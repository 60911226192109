<template>
  <div>
    <div class="table_sty">
      <div class="froms" style="margin: 5px">
        <p class="describe">系统配置</p>
        <div class="segmentation"></div>

        <el-tabs v-model="activeName">
          <el-tab-pane label="基础配置" name="first">
            <div class="from_outer">
              <div>
                <el-form
                  ref="formName"
                  label-width="200px"
                  :model="formLabelAlign"
                >
                  <el-form-item label="系统名称：">
                    <el-input
                      placeholder="请输入系统名称"
                      size="mini"
                      class="form-inp"
                      v-model="formLabelAlign.website_name"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="系统logo：">
                    <el-upload
                      class="avatar-uploader"
                      :action="this.$store.state.uploadImage"
                      :headers="this.$tools.config()"
                      :data="{
                        path_name: 'danger_icon',
                      }"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img
                        v-if="formLabelAlign.website_logo"
                        :src="formLabelAlign.website_logo"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>

                  <el-form-item label="版权信息：">
                    <el-input
                      placeholder="请输入版权信息"
                      size="mini"
                      class="form-inp"
                      v-model="formLabelAlign.website_copyright"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="备案信息：">
                    <el-input
                      placeholder="请输入备案信息"
                      size="mini"
                      class="form-inp"
                      v-model="formLabelAlign.website_beian"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="阿里云配置" name="second">
            <el-form label-width="200px" :model="formLabelAlign">
              <el-form-item label="阿里APP_KEY：">
                <el-input
                  placeholder="请输入阿里APP_KEY"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.alicloud_access_key"
                ></el-input>
              </el-form-item>

              <el-form-item label="阿里APP_SECRET：">
                <el-input
                  placeholder="请输入阿里APP_SECRET"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.alicloud_access_key_secret"
                ></el-input>
              </el-form-item>

              <el-form-item label="ENDPOINT：">
                <el-input
                  placeholder="请输入ENDPOINT"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.alicloud_endpoint"
                ></el-input>
              </el-form-item>

              <el-form-item label="BUCKET：">
                <el-input
                  placeholder="请输入BUCKET;"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.alicloud_bucket"
                ></el-input>
              </el-form-item>

              <el-form-item label="短信模板：">
                <el-input
                  placeholder="请输入短信模板;"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.sms_pwd_code_template"
                ></el-input>
              </el-form-item>

              <el-form-item label="短信签名：">
                <el-input
                  placeholder="请输入短信签名;"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.sms_sign_name"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="微信配置" name="third">
            <el-form label-width="200px" :model="formLabelAlign">
              <!-- <el-form-item label="微信开放平台 APP_KEY：">
                <el-input
                  placeholder="请输入微信开放平台 APP_KEY"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.wechat_app_key"
                ></el-input>
              </el-form-item>

              <el-form-item label="微信开放平台APP_SECRET：">
                <el-input
                  placeholder="请输入微信开放平台APP_SECRET"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.wechat_app_secret"
                ></el-input>
              </el-form-item>

              <el-form-item label="企业微信 APP_KEY：">
                <el-input
                  placeholder="请输入企业微信 APP_KEY"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.wechat_work_app_key"
                ></el-input>
              </el-form-item>

              <el-form-item label="企业微信APP_SECRET：">
                <el-input
                  placeholder="请输入企业微信APP_SECRET;"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.wechat_work_app_secret"
                ></el-input>
              </el-form-item> -->

              <el-form-item label="企业微信APP_SECRET：">
                <el-input
                  placeholder="请输入任务消息模板"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.task_templateid"
                ></el-input>
              </el-form-item>

               <el-form-item label="任务消息模板">
                <el-input
                  placeholder="请输入任务消息模板"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.task_templateid"
                ></el-input>
              </el-form-item>
              

              <el-form-item label="隐患消息模板">
                <el-input
                  placeholder="请输入隐患消息模板"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.hdanger_templateid"
                ></el-input>
              </el-form-item>

                <el-form-item label="事件消息模板">
                <el-input
                  placeholder="请输入事件消息模板"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.cases_templateid"
                ></el-input>
              </el-form-item>




              <el-form-item label="小程序APP_SECRET：">
                <el-input
                  placeholder="请输入小程序APP_SECRET;"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.wxapp_app_secret"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <div style="text-align: center">
          <el-button @click="on_submit()" type="primary">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { systemconfig, systconfigSave } from "../../assets/request/api";
export default {
  name: "system_layout",
  data() {
    return {
      activeName: "second",
      formLabelAlign: {
        website_name: "",
        website_logo: "",
        website_copyright: "",
        alicloud_access_key: "",
        alicloud_access_key_secret: "",
        alicloud_endpoint: "",
        alicloud_bucket: "",
        sms_pwd_code_template: "",
        sms_sign_name: "",
        wechat_app_key: "",
        wechat_app_secret: "",
        wechat_work_app_key: "",
        wechat_work_app_secret: "",
        wxapp_app_id: "",
        wxapp_app_secret: "",

task_templateid:"",
hdanger_templateid:"",
cases_templateid:"",

      },
    };
  },
  created() {
    this.way_list();
  },
  methods: {
    on_submit() {
      let { formLabelAlign } = this;
      systconfigSave(formLabelAlign).then((res) => {
        if (res == 200) {
          this.$message(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleAvatarSuccess(res, file) {
      console.log(file);
      this.formLabelAlign.website_logo = URL.createObjectURL(file.raw);
    },
    way_list() {
      systemconfig().then((res) => {
        let { data } = res.data;
        let { formLabelAlign } = this;
        formLabelAlign.alicloud_access_key = data.alicloud_access_key;
        formLabelAlign.alicloud_access_key_secret =
          data.alicloud_access_key_secret;
        formLabelAlign.alicloud_bucket = data.alicloud_bucket;
        formLabelAlign.alicloud_endpoint = data.alicloud_endpoint;
        formLabelAlign.sms_pwd_code_template = data.sms_pwd_code_template;
        formLabelAlign.sms_sign_name = data.sms_sign_name;
        formLabelAlign.website_beian = data.website_beian;
        formLabelAlign.website_copyright = data.website_copyright;
        formLabelAlign.website_name = data.website_name;
        formLabelAlign.wechat_app_key = data.wechat_app_key;
        formLabelAlign.wechat_app_secret = data.wechat_app_secret;
        formLabelAlign.wechat_work_app_key = data.wechat_work_app_key;
        formLabelAlign.wechat_work_app_secret = data.wechat_work_app_secret;
        formLabelAlign.wxapp_app_id = data.wxapp_app_id;
        formLabelAlign.wxapp_app_secret = data.wxapp_app_secret;

        formLabelAlign.task_templateid = data.task_templateid;
        formLabelAlign.cases_templateid = data.cases_templateid;
        formLabelAlign.hdanger_templateid = data.hdanger_templateid;




      });
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "png" ||
        file.type === "jpg" ||
        file.type === "bmp";

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG ，png ，jpg ，bmp格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.tree-form {
  margin: 5px;
  background: #ffffff;
  padding: 20px;
}

.custom-tree-node {
  display: flex;
  justify-content: space-between;
}
s .operation {
  .isty {
    padding-right: 15px;
  }
}
.custom-tree-node {
  width: 100%;
  padding-bottom: 4px;
  border-bottom: 1px solid #ebecec;
}
.el-tree-node {
  margin: 3px 0;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
